<template>
  <div v-append="message" @appended="appended"></div>
</template>

<script>
export default {
  name: "pay",
  props: ["pay"],
  data() {
    return {
      message: this.pay
    }
  },
  methods: {
    getMsg(msg) {
      this.message = msg;
    },
    appended() {
    },

  },
  mounted: function () {
  },

}
</script>